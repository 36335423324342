
import { CompanyClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import AddNewPremisesModal from '../modals/addNewPremisesModal.vue';

@Options({
    components: { },
})
export default class Premises extends Vue {

    premises: string[] = [];

    created() {
        this.init();
    }

    init() {
        CompanyClient.getPremisesByCompany()
        .then(x => {
            this.premises = x;
        })
    }

    addPremises() {
        this.$opModal.show(AddNewPremisesModal, {
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

}
