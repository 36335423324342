import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "page_header" }
const _hoisted_2 = { class: "formContainer" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { colspan: "2" }
const _hoisted_5 = { class: "py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_op_table = _resolveComponent("op-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_localized_text, {
        type: 'h1',
        localizedKey: "Organizational boundaries",
        text: "Organizational boundaries",
        class: "mb-0"
      }),
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addPremises()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Add new premises",
          text: "Add new premises"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_op_table, {
        class: "table table-striped",
        items: _ctx.premises
      }, {
        default: _withCtx((opData) => [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", null, [
                _createVNode(_component_localized_text, {
                  localizedKey: "Premises type",
                  text: "Premises type"
                })
              ])
            ])
          ]),
          _createElementVNode("tbody", null, [
            (opData.data.length == 0)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_3, [
                  _createElementVNode("td", _hoisted_4, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "No Elements Found",
                      text: "No Elements Found"
                    })
                  ])
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((opData.data as string[]), (item, i) => {
              return (_openBlock(), _createElementBlock("tr", { key: i }, [
                _createElementVNode("td", null, [
                  _createElementVNode("div", _hoisted_5, _toDisplayString(item), 1)
                ])
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["items"])
    ])
  ], 64))
}