
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { CompanyClient, EmployeeClient, ImportDataClient } from '@/services/Services';
import InfoModal from '@/components/modals/infoModal.vue';

@Options({})
export default class AddNewPremisesModal extends Vue {

    premisesType: string = "";

    @Prop() callback: any;

    created() {}

    savePremises() {
        CompanyClient.addPremises(this.premisesType)
        .then(x => {
            this.callback();
        })
        .catch(y => {
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("Error", "Error"),
                subtitle: this.$localizationService.getLocalizedValue("Type already exists!", "Type already exists!"),
                deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                denyCb: () => {
                    this.callback();
                }
            })
        })
    }
    
}
